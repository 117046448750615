import { createAction, props } from '@ngrx/store';
import {
  SatisfactionConfigData,
  SatisfactionFeedbackData,
} from '../../models/feedback-dialog-data';

export namespace SatisfactionFeedbackActions {
  export const GetUserSettings = createAction(
    '[FEEDBACK] GET_USER_SETTINGS',
    props<{ key: string }>(),
  );
  export const LoadUserSettings = createAction(
    '[FEEDBACK] LOAD_USER_SETTINGS',
    props<{ key: string }>(),
  );
  export const LoadUserSettingsSuccess = createAction(
    '[FEEDBACK] LOAD_USER_SETTINGS_SUCCESS',
    props<{ key: string; value: SatisfactionConfigData }>(),
  );
  export const LoadUserSettingsFailed = createAction(
    '[FEEDBACK] LOAD_USER_SETTINGS_FAILED',
    props<{ key: string }>(),
  );

  export const SetUserSettings = createAction(
    '[FEEDBACK] SET_USER_SETTINGS',
    props<{ key: string; value: SatisfactionConfigData }>(),
  );
  export const SetUserSettingsFinished = createAction(
    '[FEEDBACK] SET_USER_SETTINGS_FINISHED',
  );

  export const ShowSatisfactionFeedback = createAction(
    '[FEEDBACK] SHOW_SATISFACTION_FEEDBACK',
    props<{ data: SatisfactionFeedbackData }>(),
  );
}
