import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  DestroyRef,
  ElementRef,
  EventEmitter,
  Injectable,
  InjectionToken,
  Injector,
  StaticProvider,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';
import { DocumentType } from './model/document-type.model';
import { HeaderItemData } from './shared/model';
import { ConfigService } from './shared/services/config.service';

export const HEADER_ITEM_DATA = new InjectionToken<any>('headerItemData');

@Injectable({
  providedIn: 'root',
})
export class AppWrapperService {
  onOverlayClosed = new EventEmitter<void>();
  drawerState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private overlayRef: OverlayRef;

  constructor(
    private readonly overlay: Overlay,
    private readonly injector: Injector,
    private readonly destroyRef: DestroyRef,
    private readonly configService: ConfigService,
  ) {}
  showOverlay<T>(
    component,
    headerItemData?: HeaderItemData<T>,
    elementRef?: ElementRef,
  ): void {
    let positionStrategy: any;
    if (elementRef) {
      const x = elementRef.nativeElement.offsetLeft;
      const y =
        elementRef.nativeElement.offsetHeight +
        elementRef.nativeElement.offsetTop;
      positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo({ ...elementRef, width: x, height: y })
        .withPositions([
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'center',
            offsetX: x,
            offsetY: y,
          },
        ]);
    } else {
      positionStrategy = this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically();
    }

    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });
    const providers: StaticProvider[] = [
      {
        provide: HEADER_ITEM_DATA,
        useValue: headerItemData,
      },
    ];
    const componentInjector = Injector.create({
      providers,
      parent: this.injector,
    });

    const portal = new ComponentPortal(component, null, componentInjector);
    this.overlayRef.attach(portal);

    this.overlayRef
      .outsidePointerEvents()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.closeOverlay();
      });
  }

  closeOverlay(): void {
    this.overlayRef.dispose();
    this.onOverlayClosed.emit();
  }

  openInNewTab(code: string, documentType: DocumentType) {
    const url =
      documentType === DocumentType.product
        ? `${this.configService.getProductScreenUrl()}?category=${code}`
        : `${this.configService.getProductScreenUrl()}service?treeNav=${code}`;
    window.open(url, '_blank');
  }

  sanitizeOverlayRightOverflow() {
    if (!this.overlayRef?.overlayElement) {
      return;
    }
    const overlaySize = this.overlayRef.overlayElement.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    let transform = this.overlayRef.overlayElement.style.transform;
    if (overlaySize.right > viewportWidth) {
      const overflowX = overlaySize.right - viewportWidth;
      transform = transform.replace(
        /translateX\(([^)]+)\)/,
        (_, x) => `translateX(${parseFloat(x) - overflowX - 20}px)`,
      );
    }
    this.overlayRef.overlayElement.style.transform = transform;
  }

  drawerClose() {
    this.drawerState.next(false);
  }

  drawerOpen() {
    this.drawerState.next(true);
  }
}
