/* eslint-disable max-lines */
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ComponentPortal } from '@angular/cdk/portal';
import { Location } from '@angular/common';
import {
  AfterViewChecked,
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  QueryList,
  Signal,
  ViewChild,
  ViewChildren,
  booleanAttribute,
  effect,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MediaObserver } from '@angular/flex-layout';
import { matExpansionAnimations } from '@angular/material/expansion';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { dsAnimations } from '@design-system/cdk/animations';
import { dsConfig } from '@design-system/cdk/config';

import { Menu } from '@design-system/feature/app-wrapper-v2';
import {
  DsSnackbarService,
  DsSnackbarType,
} from '@design-system/feature/snackbar';
import { ApplicationContainer } from '@features/applications';
import { UserContext, UserService } from '@features/auth';
import { TranslateService } from '@ngx-translate/core';
import { HelpPagesService } from '@paldesk/design-system/feature/help-page';
import { ApiCategory } from '@paldesk/shared-lib/data-access/palipedia-service-generated';
import { ApplicationInsightsService } from '@shared-lib/app-insights';
import { Language, LanguageService } from '@shared-lib/language-selection';
import { filterTruthy } from '@shared-lib/rxjs';
import { BehaviorSubject, Observable, combineLatest, merge } from 'rxjs';
import { filter, map, startWith, tap, withLatestFrom } from 'rxjs/operators';
import { AppWrapperService } from './app-wrapper.service';
import { AppsMenuComponent } from './apps-menu/apps-menu.component';
import { DS_APP_WRAPPER_CONFIG, DsAppWrapperConfig } from './config';
import { HelpFeedbackMenuComponent } from './help-feedback-menu/help-feedback-menu.component';
import { DocumentType } from './model/document-type.model';
import { MenuConfigModel } from './model/menu-config.model';
import { DocumentsMenuComponent } from './shared/documents-menu/documents-menu.component';
import { CalculateViewportData, gAAppWrapperV2Constants } from './shared/model';
import { MetaThemeColorService } from './shared/services/meta-theme-color.service';
import { AppWrapperV2Store } from './signal-store/app-wrapper-v2-signal-store';
import { UserMenuComponent } from './user-menu/user-menu.component';

enum Menus {
  apps = 'apps',
  products = 'products',
  documents = 'documents',
  help = 'help',
  profile = 'profile',
}

@Component({
  selector: 'ds-app-wrapper',
  templateUrl: './app-wrapper.component.html',
  styleUrls: ['./app-wrapper.component.scss'],
  animations: [
    dsAnimations.fadeIn,
    dsAnimations.fadeOut,
    matExpansionAnimations.indicatorRotate,
    dsAnimations.rotate180,
    dsAnimations.rotate90,
  ],
  standalone: false,
})
export class AppWrapperComponent implements OnInit, AfterViewChecked {
  @ViewChild('drawer', { static: true }) drawer: MatDrawer;
  @ViewChild('mobileDrawer', { static: true }) mobileDrawer: MatDrawer;
  @ViewChildren('menuButtons', { read: ElementRef })
  menuButtons: QueryList<ElementRef>;
  @Input() menu: Menu;
  @Input() appNameOverride?: string;
  @Input() menuLoadingCount = 0;
  @Input({ transform: booleanAttribute }) disablePageHeadlines = false;
  @Input() defaultContentPadding = true;

  public googleAnalyticsConstants = gAAppWrapperV2Constants;

  visibleMenuItems: MenuConfigModel = {
    showProducts: false,
    showDocuments: false,
    showSearch: false,
    showHelp: false,
    showApps: false,
    showLanguageSelector: false,
  };

  menuExpandedStates = signal({
    apps: false,
    products: false,
    documents: false,
    help: false,
    profile: false,
  });

  languages: Language[];
  currentLanguage: Language;

  drawerPortal?: ComponentPortal<any>;
  isExpanded$: Observable<boolean>;
  helpMenu: Menu;
  isHelpMenuLoading$: Observable<boolean>;
  isHelpPageActive$: Observable<boolean>;
  manualSidebarToggle$: BehaviorSubject<boolean>;
  isMobileSearchActive = false;

  productCategories: Signal<ApiCategory[] | null>;
  serviceDocumentCategories: Signal<ApiCategory[] | null>;
  applications: Signal<ApplicationContainer | null>;
  loading: Signal<boolean>;
  menus = Menus;

  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    public media: MediaObserver,
    public location: Location,
    private _breakpointObserver: BreakpointObserver,
    private _helpPagesService: HelpPagesService,
    private _appInsightsService: ApplicationInsightsService,
    @Inject(DS_APP_WRAPPER_CONFIG) public config: DsAppWrapperConfig,
    public userService: UserService,
    private wrapperService: AppWrapperService,
    public store: AppWrapperV2Store,
    public translateService: TranslateService,
    private snackbar: DsSnackbarService,
    private languageService: LanguageService,
    private metaThemeColorService: MetaThemeColorService,
    private router: Router,
  ) {
    this.productCategories = this.store.productCategories;
    this.serviceDocumentCategories = this.store.serviceDocumentCategories;
    this.applications = this.store.applications;
    this.loading = this.store.loading;

    if (config.appInsightsRole) {
      this._appInsightsService.startTracking(config.appInsightsRole);
    }

    this._helpPagesService
      .getMenu$()
      .pipe(filterTruthy(), takeUntilDestroyed())
      .subscribe({
        next: (menu) => {
          this.helpMenu = this.assembleMenu(menu);
        },
      });

    this.isHelpMenuLoading$ = this._helpPagesService.getIsMenuLoading$();
    this.isHelpPageActive$ = this._helpPagesService.getIsHelpActive$();

    this.manualSidebarToggle$ = new BehaviorSubject<boolean>(
      localStorage.getItem('paldesk_sidebar_opened') !== 'false',
    );

    const breakpointSidebarToggle$ = this._breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .pipe(
        map((bpState) => !bpState.matches),
        withLatestFrom(this.manualSidebarToggle$),
        // don't emit true if sidebar was closed manually
        filter(
          ([breakpointToggle, manualToggle]) =>
            !(breakpointToggle && !manualToggle),
        ),
        map(([breakpointToggle]) => breakpointToggle),
      );

    this.isExpanded$ = merge(
      breakpointSidebarToggle$,
      this.manualSidebarToggle$,
    );

    effect(() => {
      const error = this.store.error();
      if (error) {
        this.snackbar.queue(
          this.translateService.instant('general.error_code.error'),
          {
            type: DsSnackbarType.Error,
          },
        );
      }
    });
    this.wrapperService.onOverlayClosed
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        // Reset all overlay visibility states
        Object.keys(this.menuExpandedStates()).forEach((key) => {
          this.menuExpandedStates.update((expandedState) => {
            expandedState[key] = false;
            return expandedState;
          });
        });
      });
  }

  static calculateMenuVisibility(
    userContext: UserContext | undefined,
    isNativeApp: boolean | undefined,
    isAuthorized: boolean,
    hideLanguageSelector: boolean = false,
  ): MenuConfigModel {
    let visibleMenuItems = {
      showProducts: false,
      showDocuments: false,
      showSearch: false,
      showHelp: false,
      showApps: false,
      showLanguageSelector: false,
    };

    // if user is on mobile app and its authorized or not authorized user should not see any menu items
    if (isNativeApp) {
      return visibleMenuItems;
    }
    // If user is not authorized and its on website user should see only help
    if (!isAuthorized) {
      visibleMenuItems.showHelp = true;
      visibleMenuItems.showLanguageSelector = !hideLanguageSelector;
      return visibleMenuItems;
    }

    // If user is authorized and its partner type 5 should see only help and apps
    if (userContext?.partnertype === 5) {
      visibleMenuItems = {
        showProducts: false,
        showDocuments: false,
        showSearch: false,
        showHelp: true,
        showApps: true,
        showLanguageSelector: false,
      };
      return visibleMenuItems;
    }

    // If user is authorized and its not partner type 5 should see every menu item
    if (userContext?.partnertype !== 5) {
      visibleMenuItems = {
        showProducts: true,
        showDocuments: true,
        showSearch: true,
        showHelp: true,
        showApps: true,
        showLanguageSelector: false,
      };

      // If user is partner type 6 should not see search
      if (userContext?.partnertype === 6) {
        visibleMenuItems.showSearch = false;
      }
      return visibleMenuItems;
    }

    throw new Error('Should never reach this point');
  }

  @HostListener('document:selectSiderbarNavigation')
  closeDrawer() {
    if (this.mobileDrawer) {
      this.mobileDrawer.close();
    }
  }

  ngOnInit() {
    this.store.updateHeaderState(
      combineLatest([
        this.media.asObservable(),
        this.userService.currentUser.pipe(startWith(undefined)),
      ]).pipe(
        map(
          ([media, currentUser]) =>
            ({
              media,
              currentUser,
            }) as CalculateViewportData,
        ),
        takeUntilDestroyed(this.destroyRef),
      ),
    );

    this.metaThemeColorService.setThemeColor(dsConfig.colors.primary[600]);

    let hideLanguageSelector = true;
    if (this.config.anonymousUserSupportedLanguageCodes) {
      if (this.config.anonymousUserSupportedLanguageCodes.length) {
        this.languageService.setUsedPaldeskUserInterfaceLanguages(
          this.config.anonymousUserSupportedLanguageCodes,
        );
        hideLanguageSelector = false;
      }
    } else {
      hideLanguageSelector = false;
    }
    if (!hideLanguageSelector) {
      this.languages =
        this.languageService.getUsedPaldeskUserInterfaceLanguages();
    }

    this.store.load(
      this.userService.isAuthorized$.pipe(
        takeUntilDestroyed(this.destroyRef),
        filterTruthy(),
        tap((isAuthorized) => {
          this.visibleMenuItems = AppWrapperComponent.calculateMenuVisibility(
            this.userService.userContext,
            this.config.isNativeApp,
            isAuthorized,
            hideLanguageSelector,
          );
          if (!isAuthorized && !hideLanguageSelector) {
            const paldeskLang = localStorage.getItem('paldesk_lang') || 'en';
            this.translateService.use(paldeskLang);
            this.currentLanguage = this.getLanguageByCode(paldeskLang);
          }
        }),
      ),
    );
  }

  ngAfterViewChecked(): void {
    this.wrapperService.sanitizeOverlayRightOverflow();
  }

  toggleSidebar(expand: boolean): void {
    this.manualSidebarToggle$.next(expand);
    localStorage.setItem('paldesk_sidebar_opened', expand ? 'true' : 'false');
    const evt = document.createEvent('StorageEvent');
    evt.initStorageEvent('paldesk_sidebar_event');
    window.dispatchEvent(evt);
  }

  getHelpMenu() {
    this.helpMenu = this.assembleMenu(this._helpPagesService.getLastMenu());
  }

  assembleMenu(helpMenu?: Menu): Menu {
    return helpMenu
      ? {
          ...helpMenu,
          title: helpMenu?.title ? helpMenu.title : this.menu?.title,
          titleIcon: helpMenu?.titleIcon
            ? helpMenu?.titleIcon
            : this.menu?.titleIcon,
        }
      : this.menu;
  }

  showOverlay(menu: Menus) {
    this.menuExpandedStates.update((expandedState) => {
      expandedState[menu] = !expandedState[menu];
      return expandedState;
    });

    switch (menu) {
      case Menus.apps:
        this.wrapperService.showOverlay<undefined>(
          AppsMenuComponent,
          undefined,
          this.menuButtons.find((x) => x.nativeElement.id === 'apps-menu-btn'),
        );
        break;
      case Menus.products:
        this.wrapperService.showOverlay<DocumentType>(
          DocumentsMenuComponent,
          { data: DocumentType.product },
          this.menuButtons.find(
            (x) => x.nativeElement.id === 'products-menu-btn',
          ),
        );
        break;
      case Menus.documents:
        this.wrapperService.showOverlay<DocumentType>(
          DocumentsMenuComponent,
          { data: DocumentType.service },
          this.menuButtons.find(
            (x) => x.nativeElement.id === 'documents-menu-btn',
          ),
        );
        break;
      case Menus.help:
        this.wrapperService.showOverlay<undefined>(
          HelpFeedbackMenuComponent,
          undefined,
          this.menuButtons.find((x) => x.nativeElement.id === 'help-menu-btn'),
        );
        break;
      case Menus.profile:
        this.wrapperService.showOverlay<UserContext>(
          UserMenuComponent,
          {
            data: this.userService.userContext,
          },
          this.menuButtons.find(
            (x) => x.nativeElement.id === 'profile-menu-btn',
          ),
        );
        break;
    }
  }

  navigateToDashboard() {
    window.location.href = this.config.paldeskBasePath;
  }

  navigateToRoot() {
    this.router.navigate(['/']);
  }

  setLanguage(lang: string) {
    localStorage.setItem('paldesk_lang', lang);
    this.translateService.use(lang);
    this.currentLanguage = this.getLanguageByCode(lang);
  }

  getLanguageByCode(code: string): Language {
    return (
      this.languages.find(
        (x) => x.code.toLowerCase() === code.toLowerCase(),
      ) || { code: 'en', title: 'English' }
    );
  }

  switchIsMobileSearchActive() {
    this.isMobileSearchActive = !this.isMobileSearchActive;
  }
}
