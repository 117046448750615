import { MediaChange } from '@angular/flex-layout';
import { UserContext } from '@features/auth';

export interface HeaderItemData<T> {
  data: T;
}

class GAClickListener {
  static readonly navigateTo = 'navigate_to_';
  static readonly navigateToDocument = 'navigate_to_document_';
  static readonly navigateToDashboard = 'navigate_to_dashboard';
  static readonly userMenu = 'show_profile';
  static readonly navigateToIdentityProfile =
    this.userMenu + '_' + 'navigate_to_is_profile';
  static readonly navigateToPat = this.userMenu + '_' + 'navigate_to_pat';
  static readonly openPrivacy = this.userMenu + '_' + 'open_privacy_policy';
  static readonly openTos = this.userMenu + '_' + 'open_tos';
  static readonly logout = this.userMenu + '_' + 'logout';
  static readonly showApps = 'show_apps';
  static readonly showProducts = 'show_products';
  static readonly showServiceDocuments = 'show_service_documents';
  static readonly showHelp = 'show_help';
  static readonly openFreshdeskHelp =
    this.showHelp + '_' + 'open_freshdesk_help';
  static readonly navigateToPaldeskSupport =
    this.showHelp + '_' + 'navigate_to_paldesk_status';
  static readonly openFreshdeskIdea =
    this.showHelp + '_' + 'open_freshdesk_idea';
  static readonly openFeedback = this.showHelp + '_' + 'open_feedback';
  static readonly navigateToAppHelp =
    this.showHelp + '_' + 'navigate_to_app_help';
  static readonly navigateToAppNews =
    this.showHelp + '_' + 'navigate_to_app_news';
  static readonly openAppInNewTabToggle = 'open_app_in_new_tab_toggle';
  static readonly searchSuggestion = 'search_suggestion';
  static readonly searchHistory = 'search_history';
  static readonly searchHistoryDelete = 'search_history_delete';
}

class OtherEvents {
  static readonly search = 'search';
}

class AppWrapperV2Header {
  static readonly headerName = 'app_wrapper_v2_header';
  static readonly clickListener = GAClickListener;
  static readonly otherEvents = OtherEvents;
}

class AppWrapperV2Sidebar {
  static readonly sidebarName = 'app_wrapper_v2_sidebar_mobile';
  static readonly clickListener = GAClickListener;
}

class GAAppWrapperV2Constants {
  static readonly header = AppWrapperV2Header;
  static readonly sidebar = AppWrapperV2Sidebar;
}

export const gAAppWrapperV2Constants = GAAppWrapperV2Constants;

export interface CalculateViewportData {
  media: MediaChange[];
  currentUser: UserContext;
}

export class ProblemDetails {
  type: string | undefined;
  title: string | undefined;
  status: number | undefined;
  detail: string | undefined;
  instance: string | undefined;

  /// Validation errors from backend, mapped to dictionary for easier usage inside html templates
  get errorDescription(): Record<string, string[]> | undefined {
    if (this['errors'] && Object.keys(this['errors'].length)) {
      return Object.keys(this['errors']).reduce((acc, key) => {
        acc[key] = this['errors'][key];
        return acc;
      }, {});
    }
    return undefined;
  }
}
