<div
  fxLayout="column"
  [fxLayoutGap]="1 | dsSpacing"
  class="sidebar-content scroll-container-vertical hidden-scollbar"
  [class.mini]="!isExpanded && !store.isMobileView()"
  [class.no-padding]="!isExpanded && !store.isMobileView()"
  [class.full-height]="isExpanded && !store.isMobileView()"
>
  <div
    class="header"
    fxLayout="column"
    [fxLayoutGap]="1 | dsSpacing"
    *ngIf="applicationMenu?.backButtonUrl as backUrl"
  >
    <a
      mat-button
      class="no-padding full-width back-btn"
      [routerLink]="[backUrl]"
      (click)="onItemClick(undefined, backUrl)"
      data-cy="navigation-back-button"
    >
      <ng-container
        *ngTemplateOutlet="
          btn;
          context: {
            $implicit: {
              icon: 'arrow_back',
              title: 'general.back',
            },
          }
        "
      ></ng-container>
    </a>
  </div>
  <div class="overflow-auto hidden-scollbar full-height">
    @if (!store.isMobileView()) {
      <div class="menu-header">
        <div class="no-padding" fxLayoutAlign="start center">
          <div
            [fxFlex]="2 | dsSpacing"
            class="icon-holder"
            fxLayoutAlign="center center"
          >
            <ng-container *ngIf="applicationMenu?.titleIcon as icon">
              <mat-icon *ngIf="!(icon | isPdIcon); else pdIcon"
                >{{ icon }}
              </mat-icon>
              <ng-template #pdIcon>
                <mat-icon
                  *ngIf="icon | isPdIcon"
                  fontSet="pd"
                  [fontIcon]="icon"
                ></mat-icon>
              </ng-template>
            </ng-container>
            <ds-equipment-icon
              *ngIf="applicationMenu?.equipmentIcon as icon"
              [type]="icon"
            ></ds-equipment-icon>
          </div>
          <h4 class="cut-text">
            {{ applicationMenu?.title || '' | translate }}
          </h4>
        </div>
      </div>
    }
    <div>
      <ng-container *ngIf="(hideMenu$ | async) === false">
        <div *ngIf="!menuLoadingCount; else loadingMenu">
          <div *ngFor="let menuItem of applicationMenu?.navigation">
            <div *ngIf="!menuItem.children?.length; else subMenu">
              <ng-container
                *ngTemplateOutlet="link; context: { $implicit: menuItem }"
              ></ng-container>
            </div>
            <ng-template #subMenu>
              <div
                class="relative-position"
                [routerLink]="
                  menuItem.routerLink ? [menuItem.routerLink] : undefined
                "
                [queryParams]="menuItem.queryParams"
                [queryParamsHandling]="menuItem.queryParamsHandling"
                [dsDropdown]="menuItem.children"
                [dsDropdownTitle]="menuItem.title"
                [dsDropdownEnabled]="!isExpanded && !store.isMobileView()"
              >
                <button
                  mat-button
                  class="no-padding full-width"
                  routerLinkActive="active"
                  (isActiveChange)="openItem($event, menuItem)"
                  (click)="onItemClick(menuItem)"
                  [ngClass]="{ 'info-text': menuItem.isHighlighted }"
                  #rla="routerLinkActive"
                  [attr.data-cy]="menuItem.title + '-navigate-item-button'"
                >
                  <ng-container
                    *ngTemplateOutlet="btn; context: { $implicit: menuItem }"
                  ></ng-container>
                </button>
                <button
                  class="expand-button"
                  mat-icon-button
                  *ngIf="
                    menuItem.children?.length &&
                    (isExpanded || store.isMobileView())
                  "
                  (click)="$event.stopPropagation(); toggleOpened(menuItem)"
                  [attr.data-cy]="
                    menuItem.title + '-navigate-item-expand-button'
                  "
                >
                  <mat-icon
                    [@rotate90]="isOpened(menuItem) ? 'rotated' : 'default'"
                  >
                    chevron_right
                  </mat-icon>
                </button>
              </div>
              <div
                class="expanding-panel"
                [@slideUpDown]
                *ngIf="
                  isOpened(menuItem) && (isExpanded || store.isMobileView())
                "
              >
                <div
                  class="child"
                  *ngFor="let child of menuItem.children"
                  [class.active]="rla.isActive"
                >
                  <ng-container
                    *ngTemplateOutlet="
                      childTemplate;
                      context: { $implicit: child }
                    "
                  >
                  </ng-container>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-template #loadingMenu>
    <div fxLayout="column">
      <div
        class="full-width"
        *ngFor="let load of [].constructor(menuLoadingCount)"
        fxLayoutAlign="center"
        [style.height]="2 | dsSpacing"
      >
        <ds-loading-placeholder class="full-width" [height]="1.75 | dsSpacing">
        </ds-loading-placeholder>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #childTemplate let-child>
  <a
    *ngIf="child.externalLink || !child.routerLink; else routerLnk"
    mat-button
    class="no-padding"
    [href]="child.externalLink"
    (click)="onItemClick(child); (!!child.externalLink)"
    [ngClass]="{ 'icon-badge': child.isBadgeIcon }"
    [attr.data-cy]="child.title + '-navigate-item-button'"
  >
    <span
      class="container cut-text icon-spacer relative-position"
      fxLayoutAlign="space-between center"
    >
      <span fxLayoutAlign="start center" [fxLayoutGap]="0.25 | dsSpacing">
        <span
          [matTooltip]="child.title | translate"
          [matTooltipPosition]="'right'"
          [matTooltipShowDelay]="300"
          [matTooltipDisabled]="
            (child.title | translate).length < 20 || !isExpanded
          "
        >
          {{ child.title | translate }}
        </span>
        <div
          class="badge child"
          [ngClass]="{ 'icon-badge': child.isBadgeIcon }"
          *ngIf="(child.asyncBadge$ | async) || child.badge as badge"
        >
          {{ badge }}
        </div>
      </span>

      <mat-icon class="external" *ngIf="!!child.externalLink"
        >open_in_new</mat-icon
      >
    </span></a
  >
  <ng-template #routerLnk>
    <div
      class="relative-position"
      [routerLink]="[child.routerLink]"
      [queryParams]="child.queryParams"
      [queryParamsHandling]="child.queryParamsHandling"
      fxLayout="row"
    >
      <button
        mat-button
        class="no-padding full-width"
        routerLinkActive="active"
        (isActiveChange)="openItem($event, child)"
        (click)="onItemClick(child)"
        #rla="routerLinkActive"
        [attr.data-cy]="child.title + '-navigate-item-button'"
      >
        <div fxLayout>
          <span
            class="container relative-position cut-text"
            [matTooltip]="child.title | translate"
            [matTooltipPosition]="'right'"
            [matTooltipShowDelay]="300"
            [matTooltipDisabled]="
              (child.title | translate).length < 20 || !isExpanded
            "
          >
            {{ child.title | translate }}
          </span>
          <div
            class="badge relative-position"
            [ngClass]="{ 'icon-badge': child.isBadgeIcon }"
            *ngIf="(child.asyncBadge$ | async) || child.badge as badge"
          >
            {{ badge }}
          </div>
        </div>
      </button>
      <button
        class="expand-button"
        mat-icon-button
        *ngIf="child.children?.length && (isExpanded || store.isMobileView())"
        (click)="$event.stopPropagation(); toggleOpened(child)"
        [attr.data-cy]="child.title + '-navigate-item-expand-button'"
      >
        <mat-icon
          >{{ isOpened(child) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
    </div>
    <div
      class="expanding-panel"
      [@slideUpDown]
      *ngIf="isOpened(child) && (isExpanded || store.isMobileView())"
    >
      <div
        class="child"
        *ngFor="let child of child.children"
        [class.active]="rla.isActive"
      >
        <ng-container
          *ngTemplateOutlet="childTemplate; context: { $implicit: child }"
        >
        </ng-container>
      </div>
    </div>
  </ng-template>
</ng-template>

<!-- Show <a> or <button> depending on if this is routerLink or externalLink-->
<ng-template #link let-item>
  <button
    mat-button
    class="no-padding full-width"
    [routerLink]="[item.routerLink]"
    [queryParams]="item.queryParams"
    [queryParamsHandling]="item.queryParamsHandling"
    (click)="onItemClick(item)"
    routerLinkActive="active"
    (isActiveChange)="openItem($event, item)"
    #rla="routerLinkActive"
    *ngIf="item.routerLink; else external"
    [ngClass]="{ 'info-text': item.isHighlighted }"
    [attr.data-cy]="item.title + '-navigate-item-button'"
  >
    <ng-container
      *ngTemplateOutlet="btn; context: { $implicit: item }"
    ></ng-container>
  </button>
  <ng-template #external>
    <a
      mat-button
      [href]="item.externalLink"
      target="_blank"
      (click)="onItemClick(item); (!!item.externalLink)"
      [ngClass]="{ 'info-text': item.isHighlighted }"
      [attr.data-cy]="item.title + '-navigate-item-button'"
    >
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <ng-container
          *ngTemplateOutlet="btn; context: { $implicit: item }"
        ></ng-container>
        <mat-icon
          *ngIf="!!item.externalLink"
          [fxFlex]="1.5 | dsSpacing"
          class="external"
          >open_in_new
        </mat-icon>
      </div>
    </a>
  </ng-template>
</ng-template>

<ng-template #btn let-menuItem>
  <div fxLayout="row">
    <div
      [fxFlex]="2 | dsSpacing"
      fxLayoutAlign="center center"
      *ngIf="menuItem.icon || menuItem?.equipmentIcon"
    >
      <ng-container *ngIf="menuItem.icon as icon">
        <mat-icon
          *ngIf="!(icon | isPdIcon); else pdIcon"
          [matTooltip]="menuItem.title | translate"
          [matTooltipDisabled]="isExpanded || menuItem.children?.length > 0"
          [matTooltipPosition]="'right'"
          [matBadge]="(menuItem.asyncBadge$ | async) || menuItem.badge"
          [matBadgeHidden]="
            !!(menuItem.asyncBadge$ | async) === false && !menuItem.badge
          "
          matBadgeColor="primary"
          matBadgeSize="small"
          [ngClass]="{ 'icon-badge': menuItem.isBadgeIcon }"
          >{{ icon }}
        </mat-icon>
        <ng-template #pdIcon>
          <mat-icon
            *ngIf="icon | isPdIcon"
            fontSet="pd"
            [fontIcon]="icon"
            [matTooltip]="menuItem.title | translate"
            [matTooltipDisabled]="isExpanded || menuItem.children?.length > 0"
            [matTooltipPosition]="'right'"
            [matBadge]="(menuItem.asyncBadge$ | async) || menuItem.badge"
            [matBadgeHidden]="
              !!(menuItem.asyncBadge$ | async) === false && !menuItem.badge
            "
            matBadgeColor="primary"
            matBadgeSize="small"
            [ngClass]="{ 'icon-badge': menuItem.isBadgeIcon }"
          ></mat-icon>
        </ng-template>
      </ng-container>

      <ds-equipment-icon
        *ngIf="menuItem?.equipmentIcon as icon"
        [matTooltip]="menuItem.title | translate"
        [matTooltipDisabled]="isExpanded || menuItem.children?.length"
        [matTooltipPosition]="'right'"
        [matBadge]="(menuItem.asyncBadge$ | async) || menuItem.badge"
        [matBadgeHidden]="
          !!(menuItem.asyncBadge$ | async) === false && !menuItem.badge
        "
        matBadgeColor="primary"
        matBadgeSize="small"
        [type]="icon"
        [ngClass]="{ 'icon-badge': menuItem.isBadgeIcon }"
      ></ds-equipment-icon>
    </div>
    <div
      class="cut-text icon-spacer"
      fxFlex="0 1 auto"
      [matTooltip]="menuItem.title | translate"
      [matTooltipPosition]="'right'"
      [matTooltipShowDelay]="300"
      [matTooltipDisabled]="
        (menuItem.title | translate).length < 24 || !isExpanded
      "
    >
      {{ menuItem.title | translate }}
    </div>
  </div>
</ng-template>
